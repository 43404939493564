/* Remove the outer container styles */
.table-container {
  /* These styles will be applied by the parent container */
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  overflow: hidden;
  position: relative;
}

/* Table title styles */
.table-title {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 3;
  margin-bottom: 15px;
}

.charger-header {
  font-size: 18px;
  color: #374151;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

/* Table styles */
.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-family: Arial, sans-serif;
  position: relative;
}

/* Make thead fixed */
.table thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}

/* Adjust tbody to scroll independently */
.table tbody {
  display: block;
  max-height: calc(50vh - 140px);
  overflow-y: auto;
  scroll-behavior: smooth;
  position: relative;
}

/* Keep table header row full width */
.table thead tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

/* Keep table content rows full width */
.table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-header th {
  position: sticky;
  top: 0;
  background-color: #f8f9fa;
  color: #687182;
  font-weight: 550;
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #e5e7eb;
  font-size: 12px;
  text-transform: uppercase;
  z-index: 2;
}

.table-content td {
  padding: 12px;
  border-bottom: 1px solid #dee2e6;
  color: #5e5adb;
  font-size: 14px;
}

.table-content:last-child td {
  border-bottom: none;
}

.table-content {
  background-color: white;
  cursor: pointer;
}

.table-content:nth-child(even) {
  background-color: white;
}

.table-content:hover {
  background-color: #f9fafb !important;
}

.charger-checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.table-header th:first-child,
.table-content td:first-child {
  width: 40px;
  text-align: center;
}

.table-content td:nth-child(2) {
  color: #6b7280;
  font-weight: 600;
}

.view-button {
  background-color: #6366f1;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-button:hover {
  background-color: #4f46e5;
}

.table-content td:nth-child(3) {
  color: #6366f1;
  font-weight: 600;
}

.table-content.selected {
  background-color: #f0f4ff !important;
  border-left: 3px solid #6366f1;
  transition: background-color 0.3s ease;
}

/* Ensure the striped background doesn't override our selection */
.table-content.selected:nth-child(even) {
  background-color: #f0f4ff !important;
}

/* Temporary highlight when coming from map selection */
.table-content.highlighted {
  background-color: #fff4e0 !important; /* Light orange background */
  transition: background-color 0.3s ease;
}

.table-container tbody {
  scroll-behavior: smooth;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.5rem;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
}

.close-button:hover {
  color: #333;
}

.modal-body {
  padding: 20px;
}

.modal-section {
  margin-bottom: 20px;
}

.modal-section h3 {
  margin-top: 0;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #eee;
}

.modal-section p {
  margin: 5px 0;
}
