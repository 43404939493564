.optimize-btn {
    background-color: #5E5ADE;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding: 12px 24px;
}

.optimize-btn:hover {
    background-color: #4D48C0;
}

.btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.time-of-use {
    width: 100%;
}

.advanced-parameters {
    display: flex;
    align-items: center;
    width: 100%;
}

/* Main container adjustments */
.container-flex-col.sidebar-adjustment {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
}

/* .dashboard-container {
  width: 100%;
  max-width: 100%;
  margin-bottom: 30px;
  text-align: center;
} */

/* Wizard container styles */
.wizard-container {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  padding: 40px;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 250px);
  overflow: hidden; /* Prevent overall container from scrolling */
}

/* Step progress indicator */
.step-progress {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 80%;
  max-width: 1000px;
  margin: 0 auto 60px;
  padding: 0 40px;
}

.step-progress::before {
  content: '';
  position: absolute;
  top: 70px;
  left: 100px;
  right: 100px;
  height: 4px;
  background: #E0E0E0;
  z-index: 1;
}

.step-indicator {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  position: relative;
  z-index: 2;
  cursor: pointer;
  min-width: 100px;
}

.step-number {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: white;
  border: 3px solid #5E5ADE;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  font-weight: bold;
  font-size: 22px;
  transition: all 0.3s ease;
  color: #5E5ADE;
}

.step-indicator:not(.completed):not(.active) .step-number {
  background-color: white;
  color: #5E5ADE;
  border: 3px solid #5E5ADE;
}

.step-indicator.active .step-number {
  background-color: #5E5ADE;
  color: white;
  border: 3px solid #5E5ADE;
  box-shadow: 0 0 10px rgba(94, 90, 222, 0.5);
}

.step-indicator.completed .step-number {
  background-color: #5E5ADE;
  color: white;
  border: 3px solid #5E5ADE;
}

.step-label {
  font-size: 16px;
  color: #757575;
  transition: all 0.3s ease;
  text-align: center;
  white-space: nowrap;
  margin-bottom: 5px;
}

.step-indicator.active .step-label {
  color: #5E5ADE;
  font-weight: bold;
}

.step-indicator.completed .step-label {
  color: #5E5ADE;
}

/* Step container styles */
.step-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;  
  max-height: calc(100vh - 200px); /* Adjust based on your header/footer size */
  overflow: hidden; /* Hide overflow at the container level */
  align-items: center; /* Center children horizontally */
}

/* Specifically style the parameters step */
.step-container.parameters-step {
  overflow-y: auto;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.step-container h2 {
  margin-bottom: 15px;
  color: #333;
  text-align: center;
  font-size: 24px;
  width: 100%;
}

.step-container p {
  margin-bottom: 30px;
  color: #666;
  text-align: center;
  max-width: 1200px;
  font-size: 16px;
  width: 100%;
}

/* Add space between buttons */
.step-actions {
  display: flex;
  gap: 200px; /* Adds space between buttons */
  justify-content: center;
}


/* TOU Chart container */
.tou-chart-container {
  width: 100%;
  height: 400px;
  margin-bottom: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 25px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

/* Parameters grid with scrolling */
.parameters-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 25px;
  margin-bottom: 30px;
  width: 100%;
  overflow-y: auto;
  padding-right: 10px; /* Add padding for scrollbar */
}

.parameter-group {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.parameter-group h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #333;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

/* Summary styles */
.summary-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 25px;
  margin-bottom: 30px;
  width: 100%;
  overflow-y: auto;
}

.summary-section {
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  height: 40vh;
}

.summary-section h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #333;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.summary-section p {
  color: #333;
  width: 100%;
  text-align: left;
}

.tou-summary .parameters-summary {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.summary-list, 
.tou-summary-list,
.parameters-summary-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.summary-list li,
.tou-summary-list li,
.parameters-summary-list li {
  padding: 8px 0;
  border-bottom: 1px solid #eee;
  color: #333;
}

.summary-detail {
  color: #333;
  font-size: 0.9em;
  margin-left: 5px;
}

/* Button styles */
.step-actions {
  margin-top: auto; /* Push to bottom of flex container */
  padding: 15px 0;
  display: flex;
  justify-content: space-evenly;
  background-color: #fff; /* Match your background */
  position: sticky;
  bottom: 0;
  z-index: 1;
}

.next-btn {
  background-color: #5E5ADE;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.next-btn:hover {
  background-color: #4D48C0;
}

.next-btn:disabled {
  background-color: #9e9cce;
  cursor: not-allowed;
}

.back-btn {
  background-color: white;
  color: #5E5ADE;
  border: 1px solid #5E5ADE;
  border-radius: 6px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.back-btn:hover {
  background-color: rgba(94, 90, 222, 0.1);
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Loading overlay styles */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

.loading-overlay p {
  margin-top: 20px;
  font-size: 18px;
  color: #5E5ADE;
  font-weight: 500;
  text-align: center;
}

/* Loading spinner styles */
.loading-spinner {
  width: 60px;
  height: 60px;
  border: 6px solid #f3f3f3;
  border-top: 6px solid #5E5ADE;
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Prevent body scrolling when overlay is active */
body.loading-active {
  overflow: hidden;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .wizard-container {
    padding: 25px 15px;
  }
  
  .parameters-grid,
  .summary-container {
    grid-template-columns: 1fr;
  }
  
  .step-actions {
    flex-direction: column;
    gap: 15px;
  }
  
  .next-btn, .back-btn, .optimize-btn {
    width: 100%;
  }
}

/* Style the scrollbar for better appearance */
.step-container::-webkit-scrollbar {
  width: 8px;
}

.step-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.step-container::-webkit-scrollbar-thumb {
  background: #5E5ADE;
  border-radius: 4px;
}

.step-container::-webkit-scrollbar-thumb:hover {
  background: #4D48C0;
}

/* Step container for truck selection (step 1) */
.step-container.truck-selection-step {
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Loading and error message styles */
.loading-message,
.error-message,
.no-data-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  text-align: center;
  width: 100%;
}

.loading-message p,
.error-message p,
.no-data-message p {
  margin-bottom: 20px;
  color: #666;
  font-size: 16px;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(94, 90, 222, 0.2);
  border-radius: 50%;
  border-top-color: #5E5ADE;
  animation: spin 1s linear infinite;
}

.retry-btn {
  background-color: #5E5ADE;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 20px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
}

.retry-btn:hover {
  background-color: #4D48C0;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* Single column layout for parameters */
.parameters-column {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px 0;
  overflow-y: auto;
}

/* Parameter panel styling */
.parameter-panel {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease;
  flex-shrink: 0;
}

.parameter-panel:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Panel header styling */
.panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  background-color: #f8f9fa;
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid #e0e0e0;
}

.panel-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #5E5ADE;
}

/* Toggle button styling */
.panel-toggle {
  font-size: 20px;
  font-weight: bold;
  color: #5E5ADE;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.panel-toggle.expanded {
  transform: rotate(180deg);
}

/* Panel content styling */
.panel-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.panel-content.expanded {
  max-height: 1000px; /* Adjust based on your content */
  padding: 16px 20px;
}

/* Parameter input styling */
.parameter-input {
  margin-bottom: 16px;
}

.parameter-input:last-child {
  margin-bottom: 0;
}

.parameter-input label {
  display: block;
  font-weight: 500;
  margin-bottom: 6px;
  color: #333;
}

.parameter-input input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.parameter-input .description {
  font-size: 12px;
  color: #666;
  margin-top: 4px;
}

/* Editable cell styling */
.editable-cell {
  display: flex;
  align-items: center;
  position: relative;
}

.currency-symbol {
  position: absolute;
  left: 8px;
  color: #666;
}

.incentive-input {
  width: 100%;
  padding: 6px 8px 6px 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.8);
  transition: border-color 0.2s, background-color 0.2s;
  color: rgb(60, 45, 111);
}

.incentive-input:focus {
  outline: none;
  border-color: #5E5ADE;
  background-color: white;
  box-shadow: 0 0 0 2px rgba(94, 90, 222, 0.2);
}
