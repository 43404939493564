.analytics-selection-item {
    margin-bottom: 10px;
    width: 100%;
}

.charging-station-selection {
    width: 100%;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    text-align: left;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    position: relative;
}

.charging-station-selection:hover {
    background-color: #f0f0f0;
    border-color: #ccc;
}

.station-selected {
    background-color: #e6f7ff;
    border-color: #1890ff;
    box-shadow: 0 0 5px rgba(24, 144, 255, 0.5);
}

.station-name {
    font-weight: bold;
    font-size: 1.1em;
    margin-bottom: 8px;
    display: block;
}

.station-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.station-status, .station-energy, .station-cost {
    font-size: 1.0em;
    color: #555;
}

.station-utilized {
    border-left: 4px solid green;
}

.station-not-utilized {
    border-left: 4px solid red;
}

.show-details-button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #1890ff;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 0.95em;
    align-self: flex-start;
}

.show-details-button:hover {
    background-color: #40a9ff;
}

.station-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.station-popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    width: 80%;
    max-width: 900px;
    max-height: 80vh;
    overflow-y: auto;
    z-index: 1001;
}

.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.close-popup {
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    color: #555;
}

.close-popup:hover {
    color: #000;
}

.station-info {
    margin-bottom: 20px;
}

.charts-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.chart-section {
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 10px;
    background-color: #fafafa;
}

.chart-section h3 {
    margin-bottom: 10px;
} 