.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.spinner {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #5E5ADE;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin-bottom: 20px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.spinner-text {
    font-family: Inter, sans-serif;
    font-size: 18pt;
    color: #333;
    text-align: center;
    max-width: 1000px;
}

/* Prevent body scrolling when overlay is active */
body.loading-active {
    overflow: hidden;
}