.table-container {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  overflow: hidden;
  position: relative;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-family: Arial, sans-serif;
  position: relative;
}

.table th:nth-child(1), /* Route Name */
.table td:nth-child(1) {
  width: 12%;
}

.table th:nth-child(2), /* Origin */
.table td:nth-child(2) {
  width: 30%; /* Increased from 25% */
}

.table th:nth-child(3), /* Destination */
.table td:nth-child(3) {
  width: 30%; /* Increased from 25% */
}

.table th:nth-child(4), /* Distance */
.table td:nth-child(4) {
  width: 8%; /* Reduced from 10% */
}

.table th:nth-child(5), /* Travel Time */
.table td:nth-child(5) {
  width: 12%; /* Reduced from 15% */
}

.table thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}

.table tbody {
  display: block;
  max-height: calc(50vh - 140px);
  overflow-y: auto;
  scroll-behavior: smooth;
  position: relative;
}

.table thead tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-title {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 3;
  margin-bottom: 15px;
}

.table-header th {
  position: sticky;
  top: 0;
  background-color: #f8f9fa;
  color: #687182;
  font-weight: 550;
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #e5e7eb;
  font-size: 12px;
  text-transform: uppercase;
  z-index: 2;
}

.table-content td {
  padding: 12px;
  border-bottom: 1px solid #dee2e6;
  color: #5e5adb;
  font-size: 14px;
}

.table-content:last-child td {
  border-bottom: none;
}

.table-content {
  background-color: white;
}

.route-checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.table-header th:first-child,
.table-content td:first-child {
  width: 40px;
  text-align: center;
}

.table-content td:nth-child(2) {
  color: #6b7280;
  font-weight: 600;
}

.view-button {
  background-color: #6366f1;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-button:hover {
  background-color: #4f46e5;
}

.route-header {
  font-size: 18px;
  color: #374151;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.table-content.selected {
  background-color: #f0f4ff !important;
  transition: background-color 0.3s ease;
}

.table-content.selected:nth-child(even) {
  background-color: #f0f4ff !important;
}

.table-header-container {
  margin-bottom: 10px;
}

.table-toggle-buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
}

/* .table-select-button {
  padding: 5px 12px;
  border: 1px solid #ccc;
  background-color: #f8f8f8;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
  font-size: 0.9rem;
} */

/* .table-select-button.selected {
  background-color: #5E5ADE;
  border-color: #5E5ADE;
}

.table-select-button-text {
  color: #333;
}

.table-select-button-text.selected-text {
  color: white;
} */

/* Table container with fixed height and scrolling */
.table {
  margin-bottom: 0;
  width: 100%;
}

/* Add a wrapper div around the table for scrolling */
.table-wrapper {
  max-height: calc(100vh - 250px); /* Adjust based on your layout */
  overflow-y: auto;
  padding-bottom: 20px; /* Add padding at the bottom */
  margin-bottom: 15px;
}

/* Make sure the table header stays fixed */
.table thead {
  position: sticky;
  top: 0;
  background-color: #f8f8f8;
  z-index: 1;
}

/* More specific selectors for the routing table */
.routing-table-container .table-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Use more specific selectors for all rules */
.table-header.routing-table-header .table th:nth-child(1),
.table-header.routing-table-header .table td:nth-child(1) {
  width: 12%;
}

.table-header.routing-table-header .table th:nth-child(2),
.table-header.routing-table-header .table td:nth-child(2) {
  width: 30%;
}

.table-header.routing-table-header .table th:nth-child(3),
.table-header.routing-table-header .table td:nth-child(3) {
  width: 30%;
}

.table-header.routing-table-header .table th:nth-child(4),
.table-header.routing-table-header .table td:nth-child(4) {
  width: 8%;
}

.table-header.routing-table-header .table th:nth-child(5),
.table-header.routing-table-header .table td:nth-child(5) {
  width: 12%;
}

.table-header.routing-table-header .table th:nth-child(6),
.table-header.routing-table-header .table td:nth-child(6) {
  width: 8%;
}

/* Override any inherited styles */
.table-header.routing-table-header .table td {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 0 !important;
}

.table-header.routing-table-header .table td:hover {
  white-space: normal !important;
  overflow: visible !important;
  background-color: #f8f8f8 !important;
  position: relative !important;
  z-index: 1 !important;
}

.table-header.routing-table-header .table td:nth-child(2),
.table-header.routing-table-header .table td:nth-child(3) {
  font-size: 0.9em !important;
}

.table-header.routing-table-header .table td.loading {
  color: #999 !important;
  font-style: italic !important;
}
