.map-container {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}

.leaflet-container {
  width: 100% !important;
  height: 100% !important;
  z-index: 1 !important;
}

.map-overlay {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 10px;
  right: 10px;
  background: white;
  padding: 15px;
  border-radius: 8px;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.map-overlay h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
}

.overlay-entry {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.overlay-entry:hover {
  background-color: #f0f0f0;
}

.checkbox-overlay {
  margin-right: 10px;
  cursor: pointer;
}

.map-checkbox-description {
  font-size: 14px;
  user-select: none;
  margin-left: 5px;
}

/* Add new styles for the circuit popup */
.circuit-popup {
  min-width: 280px;
  max-width: 300px;
  width: max-content;
  padding: 8px;
  font-size: 1.0rem;
}

.circuit-popup h5 {
  color: #374151;
  margin: 0 0 12px 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #E5E7EB;
  font-size: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.circuit-popup table {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  border-spacing: 0 6px;
  table-layout: fixed;
}

.circuit-popup td {
  padding: 2px 0;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.2rem;
}

.circuit-popup td:first-child {
  color: #6B7280;
  font-weight: 500;
  width: 125px;
}

.circuit-popup td:last-child {
  color: #5E5ADB;
  font-weight: 500;
  padding-left: 8px;
}

/* Override default Leaflet popup styles */
.leaflet-popup-content-wrapper {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: max-content;
}

.leaflet-popup-content {
  margin: 8px;
  width: max-content !important;
}

.overlay-section {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.overlay-section h3 {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
}

.map-resize-handle {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 15px;
  background-color: rgba(255, 255, 255, 0.8);
  cursor: ns-resize;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  border-top: 1px solid #ddd;
  transition: background-color 0.2s;
}

.map-resize-handle::after {
  content: "";
  width: 40px;
  height: 4px;
  background-color: #5E5ADE;
  border-radius: 2px;
}

.map-resize-handle:hover {
  background-color: rgba(94, 90, 222, 0.1);
}

.map-resize-handle:hover::after {
  background-color: #4D48C0;
}

/* Responsive adjustments for map and table containers */
@media (max-height: 800px) {
  .map-container-top {
      height: 35vh;
  }
  
  .table-container-bottom {
      height: 45vh;
  }
}

@media (min-height: 801px) {
  .map-container-top {
      height: 40vh;
  }
  
  .table-container-bottom {
      height: 40vh;
  }
}