
  .dashboard-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 70vh;
    text-align: center;
  }
  
  .construction-icon {
    font-size: 4rem;
    margin-bottom: 1rem;
    color: #f0ad4e;
  }
  
  .dashboard-title {
    margin-bottom: 0.5rem;
    font-size: 2rem;
  }
  
  .dashboard-subtitle {
    color: #6c757d;
    margin-bottom: 1rem;
  }
  
  .dashboard-message {
    color: #6c757d;
    max-width: 600px;
    line-height: 1.5;
  }