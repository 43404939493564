/* Table header container */
.table-header-container {
  margin-bottom: 10px;
  padding: 0 10px;
  flex-shrink: 0; /* Prevent shrinking */
}

/* Toggle buttons container */
.table-toggle-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-bottom: 5px;
  padding: 10px 0;
}

/* Button styling */
.table-select-button {
  padding: 8px 16px;
  border: 1px solid #5E5ADE;
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

/* Hover effect only for non-selected buttons */
.table-select-button:hover {
  background-color: #f5f5f5;
  border-color: #ccc;
}

/* Selected button styling - this will override the hover effect */
.table-select-button.selected {
  background-color: #5E5ADE !important; /* Use !important to override hover */
  color: white;
  border-color: #5E5ADE;
  box-shadow: 0 2px 4px rgba(94, 90, 222, 0.2);
}

/* Ensure hover doesn't change the background of selected buttons */
.table-select-button.selected:hover {
  background-color: #5E5ADE !important;
  border-color: #5E5ADE;
}

/* Table wrapper - fill available space */
.table-wrapper {
  height: 100%; /* Fill the available space */
  max-height: calc(100% - 50px); /* Leave room for the toggle buttons */
  overflow-y: auto;
  padding: 0 15px 10px;
  margin-bottom: 0;
  border-radius: 8px;
  background-color: white; /* Ensure wrapper has white background too */
}

/* Make sure the table doesn't extend beyond its container */
/* .dashboard-container {
  width: 100%;
  overflow-x: hidden;
} */

/* Ensure the table fits within its container */
.table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  background-color: white; /* Uniform background for the entire table */
}

/* Table header */
.table thead {
  position: sticky;
  top: 0;
  background-color: #5E5ADE; /* Changed to the requested purple color */
  z-index: 1;
}

.table th {
  padding: 14px 15px;
  text-align: left;
  font-weight: 600;
  border-bottom: 2px solid #4D48C0; /* Darker shade of the header color */
}

/* Table cells - increased height */
.table td {
  padding: 14px 15px; /* Increased from 10px to 14px */
  border-bottom: 1px solid #eee;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 24px; /* Set a minimum height for cells */
  line-height: 24px; /* Improve vertical alignment */
  max-width: 0; /* Forces text-overflow to work */
}

/* Show full text on hover */
.table td:hover {
  white-space: normal;
  overflow: visible;
  position: relative;
  z-index: 5;
  background-color: #f8f8f8;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
  border-radius: 3px;
}

/* Remove alternating row colors for uniform background */
.table tbody tr {
  background-color: white; /* All rows have white background */
}

/* Override the alternating row colors */
.table tbody tr:nth-child(even) {
  background-color: white; /* Make even rows white too */
}

/* Hover effect on rows */
.table tbody tr:hover {
  background-color: #f1f5f9; /* Light blue on hover */
}

/* Selected row */
.table tbody tr.selected {
  background-color: rgba(52, 152, 219, 0.1); /* Light blue for selected rows */
}

/* Highlighted row */
.table tbody tr.highlighted {
  background-color: rgba(241, 196, 15, 0.2); /* Light yellow for highlighted rows */
}

/* Select-all checkbox - adjust alignment */
.select-all-checkbox {
  /* Remove the auto margin and block display that's causing centering */
  margin: 0;
  display: inline-block;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

/* Keep the same styling for individual checkboxes */
.charger-checkbox, .depot-checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

/* Improve the highlighting for the checkbox in table headers */
.table-header .select-all-checkbox {
  background-color: white;
  border: 2px solid #5E5ADE;
}

/* Add some hover effects for better UX */
.table-header th:first-child:hover {
  background-color: rgba(94, 90, 222, 0.1);
  cursor: pointer;
} 