.route-results {
    width: 50%;
}

.summary-results {
    width: 100%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 15px;
    margin: 20px;
    margin-right: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.summary-results-big-number {
    font-size: 30pt !important;
    font-weight: bold;
}

.summary-results p {
    font-size: 14pt;
    color: #222;
}

.truck-recommendations {
    width: 100%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    margin: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.truck-recommendations-buttons {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.truck-recommendations-buttons p{
    font-size: 14pt;
    color: #222;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
}

.truck-recommendations-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.truck-recommendations-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.truck-recommendations-info-routes {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-left: 50px;
}

.truck-recommendations-info-routes p {
    font-size: 14pt;
    color: #222;
    padding-bottom: 10px;
    text-align: center;
}

.truck-recommendations-info-routes ul {
    list-style-type: disc;
    padding-left: 0;
}

.truck-recommendations-info-routes ul li {
    font-size: 12pt;
    color: #222;
    padding-bottom: 10px;
}

.truck-recommendations-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.truck-recommendations-info-title {
    font-size: 26pt !important;
    color: #222;
    padding-bottom: 10px;
    font-weight: bold;
}

.truck-recommendations-info p {
    font-size: 14pt;
    color: #222;
}

.map-results {
    width: 50%;
}

.total-tco {
    width: 100%;
} 

.tco-breakdown {
    width: 100%;
}

.run-history {
    width: 100%;
    height: 20%;
}

span.category-title {
    font-size: 14pt;
    color: #333;
}

p {
    font-size: 14pt;
}

.overview {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.cost-breakdown-container {
    width: 100%;
    padding-top: 0px;
}

.cost-breakdown {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 0px;
    border-radius: 20px;
    flex-grow: 1;
    padding: 20px;
    margin: 50px;
}

.analytics-button-sidebar {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 50px;
}

.map-analytics {
    padding-top: 0px;
    padding-bottom: 20px;
    /* overflow: hidden; */
    height: 650px;
    /* width: 1200px; */
    flex-grow: 1;
    margin-right: 40px;
}

.route-sidebar {
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
    margin-right: 5px;
    width: 450px;
    border-right: 1px solid #e0e0e0;
}

.route-sidebar::-webkit-scrollbar {
    width: 8px;
}

.route-sidebar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.route-sidebar::-webkit-scrollbar-thumb {
    background: #5E5ADE;
    border-radius: 4px;
}

.route-sidebar::-webkit-scrollbar-thumb:hover {
    background: #4D48C0;
}

.map-analytics-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.container-flex-row {
    display: flex;
    width: 100%;
}

.map-analytics {
    flex: 1;
    min-height: 400px;
    position: relative;
}

.analytics-route-selection,
.analytics-depot-selection {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 6px;
    transition: background-color 0.2s;
    cursor: pointer;
}

.analytics-route-selection:hover,
.analytics-depot-selection:hover {
    background-color: rgba(94, 90, 222, 0.05);
}

.analytics-route-selection.selected,
.analytics-depot-selection.selected {
    background-color: rgba(94, 90, 222, 0.1);
    border-left: 3px solid #5E5ADE;
}

/* .route-summary {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 20px;
    margin: 20px;
    margin-right: 50px;
} */

.info-type-selector {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.filter-controls {
  display: flex;
  align-items: center;
  margin-top: 0px;
  padding: 0px 0px 30px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.filter-controls label {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

.filter-controls input[type="checkbox"] {
  margin-right: 8px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  accent-color: #5E5ADE;
}

.filter-controls label:hover {
  color: #5E5ADE;
}
/* Container for the image and overlay */
.truck-image-container {
  position: relative;
  display: inline-block;
  width: 70%;
}

/* Style for the truck image */
.truck-recommendations-info-image {
  display: block;
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

/* Style for the link icon overlay */
.link-icon-overlay {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.6);
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  transition: all 0.2s ease;
}

/* Hover effect for the overlay */
.truck-image-container:hover .link-icon-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  transform: scale(1.1);
}

.selected-case-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f1f8ff;
  border-left: 4px solid #3498db;
  padding: 10px 15px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.selected-case-banner h3 {
  margin: 0;
  color: #2c3e50;
}

.return-to-latest {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.return-to-latest:hover {
  background-color: #2980b9;
}

.loading-overlay {
  text-align: center;
  padding: 20px;
  font-style: italic;
  color: #888;
}
