.container-flex-col.sidebar-adjustment {
    position: relative;
    padding-bottom: 80px;
}

.information-popup-container {
    width: 600px;
    height: 500px;
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
}

.information-popup-content {
    color: #333;
}

/* Fixed layout container */
.my-operation-fixed-layout {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px); /* Full viewport height minus header */
    overflow: hidden; /* Prevent overall scrolling */
}

/* Map section - top half */
.map-section {
    height: 50%; /* Take exactly half of the container height */
    width: 100%;
    position: relative;
    overflow: hidden;
    border: 1px solid #e0e0e0;
    border-radius: 8px 8px 0 0;
    margin-bottom: 10px;
}

/* Tables section - bottom half */
.tables-section {
    height: 50%; /* Take exactly half of the container height */
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

/* Table container with scrolling */
.table-container {
    flex: 1;
    overflow-y: auto; /* Enable vertical scrolling within tables */
    border: 1px solid #e0e0e0;
    border-radius: 0 0 8px 8px;
    background-color: white;
}

/* Table with buttons container */
.table-with-buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

/* Button area below table */
.button-area {
    display: flex;
    gap: 10px;
    margin-top: 15px;
}

/* Table select buttons */
.table-select-container {
    display: flex;
    margin-bottom: 10px;
    padding: 10px 0;
}

.table-select-button {
    font-family: Inter;
    font-size: 12pt;
    height: 50px;
    width: 250px;
    background-color: #fff;
    border: solid;
    border-color: #5E5ADE;
    border-radius: 20px 20px 0px 0px;
    color: #5E5ADE;
    margin-right: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.table-select-button.active {
    background-color: #5E5ADE;
    color: white;
}

.table-select-button:hover:not(.active) {
    background-color: rgba(94, 90, 222, 0.1);
}

/* Scrollbar styling */
.table-container::-webkit-scrollbar {
    width: 8px;
}

.table-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.table-container::-webkit-scrollbar-thumb {
    background: #5E5ADE;
    border-radius: 4px;
}

.table-container::-webkit-scrollbar-thumb:hover {
    background: #4D48C0;
}

.table-select-button-text{
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    color: #5E5ADE;
}

.table-select-button-text.selected-text{
    color: #fff;
}

.info-button {
    border: none;
    background-color: #FFF;
    color: #333;
    font-family: Inter;
    padding: 5px;
    margin: 10px;
}

.info-button svg {
    display: flex;
    width: 18px;
    height: 18px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-top: 10px;
}

.delete-selected-btn {
    background-color: #ef4444;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px;
}

.delete-selected-btn:hover {
    background-color: #dc2626;
}

.delete-selected-btn:disabled {
    background-color: #f87171;
    cursor: not-allowed;
}

/* Action buttons container */
.my-operation-actions {
  display: flex;
  gap: 10px;
  margin-left: auto;
}   

.map-container-top {
    height: 40vh;
}

.table-container-bottom {
    height: 40vh;
}

/* Map container with resize functionality */
.map-dashboard-container {
    position: relative;
    width: 100%;
    min-height: 300px; /* Minimum height for the map */
    border-radius: 8px;
    overflow: hidden;
    resize: vertical; /* Allow vertical resizing */
}

