.table-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-height: 50vh;
  overflow: hidden;
}

.table-title {
  margin-bottom: 20px;
}

.depot-header {
  font-size: 18px;
  color: #374151;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-family: Arial, sans-serif;
}

.table-header th {
  background-color: #f8f9fa;
  color: #687182;
  font-weight: 550;
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #e5e7eb;
  font-size: 12px;
  text-transform: uppercase;
}

.table-content td {
  padding: 12px;
  border-bottom: 1px solid #dee2e6;
  color: #5e5adb;
  font-size: 14px;
}

.table-content:last-child td {
  border-bottom: none;
}

.table-content:nth-child(even) {
  background-color: #f8f9fa;
}

.table-content td:first-child {
  color: #6b7280;
  font-weight: 600;
}

.edit-button {
  background-color: #6366f1;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-button:hover {
  background-color: #4f46e5;
}

.depot-checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

/* Adjust the first column (checkbox column) width */
.table-header th:first-child,
.table-content td:first-child {
  width: 40px;
  min-width: 40px;
}

/* Adjust the ID column style since it's no longer the first column */
.table-content td:nth-child(2) {
  color: #6b7280;
  font-weight: 600;
}

.table td,
.table th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 12px;
}

.selected-row {
  background-color: rgba(0, 123, 255, 0.1);
}

.table-content:hover {
  background-color: rgba(0, 123, 255, 0.05);
}

.table-header th:nth-child(2),
.table-content td:nth-child(2) {
  width: 30px;
  min-width: 30px;
}

.table-header th:nth-child(3),
.table-content td:nth-child(3) {
  width: 10%;
  min-width: 120px;
}

.table-header th:nth-child(4),
.table-content td:nth-child(4) {
  width: 20%;
  min-width: 200px;
}

.table-header th:nth-child(5),
.table-content td:nth-child(5) {
  width: 10%;
  min-width: 120px;
}

.table-header th:last-child,
.table-content td:last-child {
  width: 100px;
  min-width: 100px;
}

