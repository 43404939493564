.analytics-box-container{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-shrink: 0;
    width: 300px;
    height: 125px;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.analytics-title {
    color: #000;
    font-family: Inter;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: normal;
    margin-left: 20px;
}

.analytics-value {
    color: #000;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 20px;
}