.analytics-route-item {
    margin-bottom: 10px;
    width: 100%;
}

.route-selection {
    width: 100%;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    text-align: left;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    position: relative;
}

.route-selection:hover {
    background-color: #f0f0f0;
    border-color: #ccc;
}

.route-selected {
    background-color: #e6f7ff;
    border-color: #1890ff;
    box-shadow: 0 0 5px rgba(24, 144, 255, 0.5);
}

.route-name {
    font-weight: bold;
    font-size: 1.1em;
    margin-bottom: 8px;
    display: block;
}

.route-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.route-status, .route-origin, .route-destination {
    font-size: 1.0em;
    color: #555;
}

.route-electric {
    border-left: 4px solid green;
}

.route-diesel {
    border-left: 4px solid red;
}

.colored-circle {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: -1px;
    border-radius: 50%;
    border-style: none;
    height: 12px;
    width: 12px;
}

.show-details-button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #1890ff;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 0.95em;
    align-self: flex-start;
}

.show-details-button:hover {
    background-color: #40a9ff;
}

.route-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.route-popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    width: 80%;
    max-width: 1000px;
    max-height: 80vh;
    overflow-y: auto;
    z-index: 1001;
}

.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.close-popup {
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    color: #555;
}

.close-popup:hover {
    color: #000;
}

.route-info {
    margin-bottom: 20px;
}

.charts-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.chart-section {
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 10px;
    background-color: #fafafa;
}

.chart-section h3 {
    margin-bottom: 10px;
}

.trip-itinerary-section {
    margin: 0px 0;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
}

.itinerary-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    font-size: 15px;
}

.itinerary-table th,
.itinerary-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.itinerary-table th {
    background-color: #f0f0f0;
    font-weight: bold;
}

.itinerary-table tr:nth-child(even) {
    background-color: #f5f5f5;
}

.itinerary-table tr:hover {
    background-color: #e9e9e9;
}

.itinerary-stops {
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 4px;
}