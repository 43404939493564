/* Filter styling */
.column-header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .filter-container {
    position: relative;
    display: inline-block;
    margin-left: 8px;
  }
  
  .filter-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0px;
    color: #666;
  }
  
  .filter-button:hover, .active-filter {
    color: #0066cc;
  }
  
  .filter-dropdown {
    width: 250px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    z-index: 9999; /* High z-index since it's at document.body level */
    margin-top: 5px;
  }
  
  .filter-search {
    padding: 10px;
    border-bottom: 1px solid #eee;
  }
  
  .filter-search input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .filter-options {
    max-height: 300px;
    overflow-y: auto;
  }
  
  .filter-actions {
    padding: 5px;
    border-bottom: 3px solid #eee;
    text-align: right;
  }
  
  .filter-actions button {
    background-color: #f0f0f0;
    border: none;
    padding: 3px 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .filter-actions button:hover {
    background-color: #e0e0e0;
  }
  
  .filter-list {
    padding: 10px;
  }
  
  .filter-option {
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
  }
  
  .filter-option input[type="checkbox"] {
    margin-right: 8px;
    margin-top: 2px;
  }
  
  .filter-option label {
    display: inline-block;
    margin-bottom: 0;
    line-height: 1.4;
  }
  
  .filter-list {
    horizontal-align: top;
  }
  
  .checkbox-column {
    text-align: center;
    vertical-align: top;
  }
  
  .checkbox-column input[type="checkbox"] {
    cursor: pointer;
    vertical-align: top;
    margin-top: 3px;
  }
  
  .table-container {
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .table-wrapper {
    overflow-y: auto;
    max-height: 500px; /* Adjust this value as needed */
    position: relative;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;
    background-color: #fff;
    table-layout: fixed; /* This helps maintain column widths */
  }
  
  .table th,
  .table td {
    padding-left: 15px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
    vertical-align: top;
  }
  
  .table thead {
    background-color: #f5f5f5;
    font-weight: 600;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  
  .table tbody tr:hover {
    background-color: #f9f9f9;
  }
  
  .selected-row {
    background-color: #e8f4fd !important;
  }
  
  /* Add this to handle the scrollbar placeholder */
  .scrollbar-placeholder {
    width: 5px; /* Approximate width of a scrollbar */
    padding: 0 !important;
    border: none !important;
  }
  
  