.analytics-button-container {
    display: flex;
}

.analytics-button-select {
    color: #000;
    font-family: Inter;
    background: #fff;
    border: none;
    width: 200px;
    height: 50px;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.selected {
    color: #FFF;
    background: #5E5ADE;
}