/* Main container for the login page */
.login-landing-container {
    display: flex;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

/* Left half with background image */
.login-background-half {
    flex: 1;
    /* background-image: '\img\ev-truck-fleet.png'; */
    /* TODO: FIX PATH */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100%;
    position: relative;
}

/* Right half with login form */
.login-form-half {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: #FFF;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    width: 50%;
    max-width: 50%;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .login-landing-container {
        flex-direction: column;
    }

    .login-background-half {
        flex: none;
        height: 30vh;
    }

    .login-form-half {
        flex: none;
        height: 70vh;
    }
}

/* Login form title */
.login-title {
    font-size: 1.75rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    text-align: center;
    color: #333;
}

/* Form input styling */
.login-input {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

/* Login button styling */
.login-button {
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    color: #fff;
    background-color: #5ca9fb;
    background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
    padding: 14px 34px;
    letter-spacing: 1px;
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    border-radius: 25px;
    transition: all 0.5s linear;
    border: 0;
    width: 250px;
    min-width: 250px;
}

.login-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

/* Error message styling */
.login-error {
    color: #d32f2f;
    margin-bottom: 1rem;
    font-size: 0.875rem;
}

.login-image-overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 15%;
    /* Position 15% from the bottom */
    width: 100%;
    color: white;
    z-index: 10;
    text-align: center;
    padding: 0 5%;
}

.login-image-overlay p {
    font-family: 'Inter';
    font-size: 48px;
    font-style: normal;
    font-weight: 100;
    text-align: center;
    line-height: 1.2;
    letter-spacing: 1.2px;
    max-width: 80%;
    margin: 0 auto;
    /* Center the paragraph horizontally */
}

.g-signin2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

/* Login form wrapper - centers the form */
.login-form-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

/* Login form container */
.login-form-container {
    width: 100%;
    max-width: 500px;
    background-color: white;
    border-radius: 8px;
    padding: 2rem;
}

/* Home button positioning */
.login-form-half .home-button {
    position: absolute;
    top: 20px;
    right: 20px;
}


.login-form-group {
    margin-bottom: 2rem;
}

.login-form-group label {
    display: block;
    margin-bottom: 1rem;
    color: #333;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.login-form-group input {
    width: 100%;
    padding: 1rem;
    height: 32px;
    border-radius: 20px;
    background-color: #F0F0F0;
    border: none;
}

.login-form-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 600px;
    padding-bottom: 20px;
}

.login-form-text {
    margin: 0;
    color: #666;
}

/* Update the btn-link style */
.btn-link {
    background: none;
    border: none;
    color: #4285f4;
    text-decoration: underline;
    cursor: pointer;
    padding: 10px 0;
    margin: 0;
    font-size: inherit;
    line-height: inherit;
    display: inline;
}

.login-home-button-container {
    top: 200px;
    /* right: 60px; */
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px; /* Space between text and button */
    width: 400px;
}

p.login-form-text {
    font-family: 'Inter';
    color: #666;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: center;
}

.login-name-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: row;
}

/* Style for required field indicator */
.required-asterisk {
  color: #e53935; /* Red color */
  margin-left: 3px;
  font-weight: bold;
}

/* Optional: Add a note about required fields at the bottom of the form */
.required-fields-note {
  font-size: 0.8rem;
  color: #666;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
