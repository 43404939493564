.add-button-custom {
    font-family: Inter;
    height: 32px;
    width: 200px;
    background-color: #5E5ADE;
    border: none;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.2s ease;
    display: inline-block;
    margin-right: 10px;
}

.add-button-custom:hover {
    background-color: #4D48C0;
}

/* Remove all fixed/absolute positioning */
.btn-container {
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 15px;
}

/* Remove all positioning classes */
.btn-container .top-right,
.btn-container .bottom-left,
.btn-container .bottom {
    position: static;
    display: inline-block;
}

.btn-container svg {
    display: inline-block;
    height: 2em;
    vertical-align: -0.65em;
}

.popup-title {
    font-family: Inter;
    color: #4E5A82;
    padding-bottom: 25px;
    padding-left: 10px;
}

.popup-content {
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    overflow-y: auto;
}

.popup-input-group-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.popup-input-group {
    width: 95%;
    margin-bottom: 15px;
    position: relative;
}

.popup-input-group-label {
    color: #4E5A82;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 10px;
}

.popup-input {
    border: 1px solid #808080;
    border-radius: 10px;
    font-family: Inter;
    color: #4E5A82;
    height: 30px;
    padding-left: 10px;
    width: 100%;
}

.popup-input-route {
    border: 1px solid #808080;
    border-radius: 10px;
    font-family: Inter;
    color: #4E5A82;
    height: 30px;
    padding-left: 10px;
    width: 50%;
}

.add-button-container {
    right: 40%;
    position: absolute;
    bottom: 25px;
    align-items: center;
}

.add-button-popup {
    font-family: Inter;
    position: relative;
    height: 32px;
    width: 150px;
    background-color: #5E5ADE;
    border: none;
    border-radius: 6px;
    color: #fff;
}

.add-button-container svg {
    display: inline-block;
    height: 2em;
    vertical-align: -0.6em;
}

.popup-input-group .relative {
    width: 100%;
}

.popup-input-group .absolute {
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
}

/* Address input specific styles */
.loading-spinner {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.spinner {
    width: 16px;
    height: 16px;
    border: 2px solid #5E5ADE;
    border-top: 2px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.address-suggestions {
    position: absolute;
    top: calc(100% - 5px);
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #808080;
    border-radius: 6px;
    margin-top: 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.suggestion-item {
    padding: 8px 12px;
    cursor: pointer;
    font-family: Inter;
    color: #4E5A82;
}

.suggestion-item:hover {
    background-color: #f5f5f5;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.error-message {
    color: #ff0000;
    font-size: 0.8rem;
    margin-top: 4px;
}

.add-button-custom:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.add-button-custom.delete {
    background-color: #ff4444;
}

.add-button-custom.delete:hover {
    background-color: #ff0000;
}

/* Buttons */
button.add-route-btn, button.remove-route-btn{
    background-color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    /* Adjust space between icon and text */
    border: none;
    background-color: transparent;
    font-size: 16px;
    cursor: pointer;
    color: #4E5A82;
}

.stop-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #f9f9ff;
    position: relative;
}

.stop-row .popup-input-group-label {
    font-size: 16px;
    color: #5E5ADE;
    margin-bottom: 8px;
}

.stop-row .remove-route-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #ff4444;
}

.stop-row .remove-route-btn:hover {
    color: #ff0000;
}

/* Loading overlay - positioned in center of screen */
.loading-overlay-form {
    position: fixed; /* Changed from absolute to fixed */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
}

.loading-spinner-form {
    width: 60px; /* Slightly larger */
    height: 60px; /* Slightly larger */
    border: 6px solid rgba(94, 90, 222, 0.2);
    border-radius: 50%;
    border-top-color: #5E5ADE;
    animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* Make sure the popup container has position relative for absolute positioning of overlay */
.popup-container {
    position: relative;
    z-index: 999; /* Ensure popup is below the loading overlay */
}

/* Disable button styling */
.add-button-popup:disabled {
    background-color: #a8a7e0;
    cursor: not-allowed;
}

/* Route-specific styling improvements */
.add-route-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5E5ADE;
    font-weight: 600;
    padding: 8px 12px;
    border-radius: 6px;
    transition: background-color 0.2s;
    margin-top: 10px;
    width: fit-content;
}

.add-route-btn:hover {
    background-color: rgba(94, 90, 222, 0.1);
}

/* Idle time input styling */
.popup-input-idle-time {
    border: 1px solid #808080;
    border-radius: 10px;
    font-family: Inter;
    color: #4E5A82;
    height: 30px;
    padding-left: 10px;
    width: 100%;
    margin-top: 10px;
}

/* Helper text for inputs */
.input-helper-text {
    display: block;
    color: #6c757d;
    font-size: 12px;
    margin-top: 4px;
}

/* Dynamic route form section */
.dynamic-route-section {
    background-color: #f5f5f9;
    border-radius: 8px;
    padding: 15px;
    margin-top: 15px;
    border: 1px dashed #5E5ADE;
    width: 95%;
    max-height: 350px;
    overflow-y: auto;
}

.dynamic-route-section-title {
    font-weight: 600;
    color: #5E5ADE;
    margin-bottom: 15px;
    font-size: 16px;
}